import React, { useEffect, useState } from "react";
import { Row } from "style/custom/useCustomLayout";
import { TextBox } from "style/useTextStyles";
import styled from "styled-components";
import Tooltip from "./Tooltip";

const IconImg = styled.img`
  display: ${(src) => (src ? 'block' : 'none')};
  height: 20px;
`;

const UserLevelTab = ({ item }) => {
  const [state2, setState2] = useState([]);

  useEffect(() => {
    if (item) {
      try {
        setState2(item);

      } catch (error) {
        setState2([]);
      }
    }
  }, [item]);

  return (
    state2 && (
      <Row $gap="6px" $align="center" $jus="center">
        <Tooltip text={`${item.lv}. ${item.level_name}`}>
          <IconImg src={item.img_uuid == null || item.img_uuid == '' ? "" : process.env.REACT_APP_IMG_URL + item.img_uuid}></IconImg>
        </Tooltip>
        {/* <TextBox>{`${item.level_name || "-"} (${item.lv !== undefined && item.lv !== null ? item.lv : "-"})`}</TextBox> */}
      </Row>
    )
  );
};

export default UserLevelTab;
