import { t } from "i18next";
import i18n from "locales/i18n";

const dbUser = {
  state1: {
    S: {
      label: i18n.t("userState1.S"),
      value: "S",
      key: "S",
    },
    B: {
      label: i18n.t("userState1.B"),
      value: "B",
      key: "B",
    },
    O: {
      label: i18n.t("userState1.O"),
      value: "O",
      key: "O",
    },
    W: {
      label: i18n.t("userState1.W"),
      value: "W",
      key: "W",
    },
    T: {
      label: i18n.t("userState1.T"),
      value: "T",
      key: "T",
    },
  },

  lv: {
    0: {
      label: "LV0",
      value: 0,
      key: 0,
    },
    1: {
      label: "LV1",
      value: 1,
      key: 1,
    },
    2: {
      label: "LV2",
      value: 2,
      key: 2,
    },
    3: {
      label: "LV3",
      value: 3,
      key: 3,
    },
    4: {
      label: "LV4",
      value: 4,
      key: 4,
    },
    5: {
      label: "LV5",
      value: 5,
      key: 5,
    },
  },
  first_charge_point_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  every_charge_point_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  ip_block_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
};

const dbUserBoardQna = {
  state: {
    A: {
      label: t("common.all"),
      value: "",
      key: "A",
    },
    P: {
      label: t("agentQna.no_answer"),
      value: "P",
      key: "P",
    },
    C: {
      label: t("agentQna.answer"),
      value: "C",
      key: "C",
    },
  },
  search_date_state: {
    P: {
      label: "문의 접수일",
      value: "P",
      key: 1,
    },
    C: {
      label: "답변 처리일",
      value: "C",
      key: 2,
    },
  },
};

const dbUserHistoryAccount = {
  inout: {
    IN: {
      label: "입금(요청)",
      value: "IN",
      key: "IN",
    },
    OUT: {
      label: "출금(요청)",
      value: "OUT",
      key: "OUT",
    },
  },
  account_bank: {
    IN: {
      label: "입금 통장",
      value: "IN",
      key: "IN",
    },
    OUT: {
      label: "고객 통장",
      value: "OUT",
      key: "OUT",
    },
  },
  state: {
    D: {
      label: "요청",
      value: "D",
      key: "D",
    },
    P: {
      label: "처리",
      value: "P",
      key: "P",
    },
    B: {
      label: "불가",
      value: "B",
      key: "B",
    },
    C: {
      label: "취소",
      value: "C",
      key: "C",
    },
  },
};

const dbUserHistoryGame = {
  bet_type: {
    B: {
      label: t("gameManagement.betting"),
      value: "B",
      key: "B",
    },
    W: {
      label: t("gameManagement.win"),
      value: "W",
      key: "W",
    },
    N: {
      label: t("gameManagement.not_win"),
      value: "N",
      key: "N",
    },
    C: {
      label: t("gameManagement.cancel"),
      value: "C",
      key: "C",
    },
  },
};

const dbUserMessage = {
  state: {
    R: {
      label: "확인",
      key: 0,
      value: "R",
    },
    N: {
      label: "미확인",
      key: 1,
      value: "N",
    },
    E: {
      label: "미확인",
      key: 2,
      value: "E",
    },
    D: {
      label: "삭제",
      key: 3,
      value: "D",
    },
  },
};

const dbUserState2 = {
  state2: {
    H: {
      label: i18n.t("userState2.H"),
      value: "H",
      key: "H",
    },
    B: {
      label: i18n.t("userState2.B"),
      value: "B",
      key: "B",
    },
    T: {
      label: i18n.t("userState2.T"),
      value: "T",
      key: "T",
    },
    O: {
      label: i18n.t("userState2.O"),
      value: "O",
      key: "O",
    },
    M: {
      label: i18n.t("userState2.M"),
      value: "M",
      key: "M",
    },
    E: {
      label: i18n.t("userState2.E"),
      value: "E",
      key: "E",
    },
  },
};

const dbUserStatisticsGameDay = {
  payment_type: {
    S: {
      label: i18n.t("gameStatistics.slot"),
      value: "S",
      key: "S",
    },
    C: {
      label: i18n.t("gameStatistics.casino"),
      value: "C",
      key: "C",
    },
    M: {
      label: i18n.t("gameStatistics.mini_game"),
      value: "M",
      key: "M",
    },
    P: {
      label: i18n.t("gameStatistics.sports"),
      value: "P",
      key: "P",
    },
  },
};

const dbUserStatisticsGameMonth = {
  payment_type: {
    S: {
      label: "slot",
      value: "S",
      key: "S",
    },
    C: {
      label: "casino",
      value: "C",
      key: "C",
    },
    M: {
      label: "mini game",
      value: "M",
      key: "M",
    },
    P: {
      label: "sports",
      value: "P",
      key: "P",
    },
  },
};

const dbUserStatisticsGameTotal = {
  payment_type: {
    S: {
      label: "slot",
      value: "S",
      key: "S",
    },
    C: {
      label: "casino",
      value: "C",
      key: "C",
    },
    M: {
      label: "mini game",
      value: "M",
      key: "M",
    },
    P: {
      label: "sports",
      value: "P",
      key: "P",
    },
  },
};

const dbUserTransactionsMoney = {
  type: {
    A: {
      label: "계좌 입출",
      value: "A",
      key: "A",
    },
    G: {
      label: "게임 플레이",
      value: "G",
      key: "G",
    },
    P: {
      label: "포인트 머니 전환",
      value: "P",
      key: "P",
    },
    C: {
      label: "관리자 수동 처리",
      value: "C",
      key: "C",
    },
  },
  inout: {
    IN: {
      label: "입금",
      value: "IN",
      key: "IN",
    },
    OUT: {
      label: "출금",
      value: "OUT",
      key: "OUT",
    },
  },
  ref_type: {
    A: {
      label: "Acount 관련(user_history_acount)",
      value: "A",
      key: "A",
    },
    G: {
      label: "게임 관련(user_history_game)",
      value: "G",
      key: "G",
    },
    P: {
      label: "포인트 관련(user_transaction_point)",
      value: "P",
      key: "P",
    },
  },
};
const dbUserHistoryPoint = {
  inout: {
    IN: {
      label: "머니 증가",
      value: "IN",
      key: "IN",
    },
    OUT: {
      label: "머니 감소",
      value: "OUT",
      key: "OUT",
    },
  },

  game_category: {
    S: {
      label: "slot",
      value: "S",
      key: "S",
    },
    C: {
      label: "casino",
      value: "C",
      key: "C",
    },
    M: {
      label: "mini game",
      value: "M",
      key: "M",
    },
    P: {
      label: "sports",
      value: "P",
      key: "P",
    },
  },

  type: {
    P: {
      label: "환전",
      value: "P",
      key: "P",
    },
    R: {
      label: "회수",
      value: "R",
      key: "R",
    },
    C: {
      label: "유저 콤프",
      value: "C",
      key: "C",
    },
    M: {
      label: "추천인 콤프",
      value: "M",
      key: "M",
    },
    G: {
      label: "추가 콤프",
      value: "G",
      key: "G",
    },
    L: {
      label: "등업",
      value: "L",
      key: "L",
    },
    I: {
      label: "가입첫충",
      value: "I",
      key: "I",
    },
    A: {
      label: "관리자",
      value: "A",
      key: "A",
    },
    J: {
      label: "가입",
      value: "J",
      key: "J",
    },
    F: {
      label: "첫 충",
      value: "F",
      key: "F",
    },
    E: {
      label: "매 충",
      value: "E",
      key: "E",
    },
    B: {
      label: "이벤트 보너스",
      value: "B",
      key: "B",
    },
  },
  point_sub_type : {
    L : {
      label: "루징",
      value: "L",
      key: "L",
    }, 
    R : {
      label: "롤링",
      value: "R",
      key: "R",
    }
  },
  state: {
    D: {
      label: "요청",
      value: "D",
      key: "D",
    },
    P: {
      label: "처리",
      value: "P",
      key: "P",
    },
    B: {
      label: "불가",
      value: "B",
      key: "B",
    },
    C: {
      label: "취소",
      value: "C",
      key: "C",
    },
  },
};

const dbUserCommissions = {
  personalCompYn: {
    Y: {
      value: "Y",
      label: "전역설정",
    },
    N: {
      value: "N",
      label: "개별설정",
    },
  },
};

const dbUserAgentChange = {
  state: {
    D: {
      value: "D",
      key: "D",
      label: i18n.t("user.request"),
    },
    P: {
      value: "P",
      key: "P",
      label: i18n.t("user.agent_change"),
    },
    B: {
      value: "B",
      key: "B",
      label: i18n.t("user.change_block"),
    },
  },
};

export {
  dbUser,
  dbUserBoardQna,
  dbUserHistoryAccount,
  dbUserHistoryGame,
  dbUserMessage,
  dbUserState2,
  dbUserStatisticsGameDay,
  dbUserStatisticsGameMonth,
  dbUserStatisticsGameTotal,
  dbUserTransactionsMoney,
  dbUserHistoryPoint,
  dbUserCommissions,
  dbUserAgentChange,
};
