import { useEffect } from "react";

import useFormData from "common/useFormData";
import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import api from "interceptor/api";

import useStateData from "common/useStateData";
import { EachCheckForm, EachCheckFormCustom, ImageForm, InputForm, RadioForm, TextareaEditorForm, TextareaForm } from "components/common/CommonForm";
import { Column, Row } from "style/custom/useCustomLayout";
import { Label, TextBox } from "style/useTextStyles";
import { t } from "i18next";
import styled from "styled-components";
import CalendarFilter from "components/common/CalendarFilter";
import { dbSiteEventMng } from "enum/db/useSiteEnum";
import { checkboxYNEnum, validationTypeEnum } from "enum/useCommonEnum";
import { LineSection } from "style/useLayoutStyles";
import { MainBtn, RedBtn } from "style/useButtonStyles";

const DisabledWrapper = styled.div`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

const InsertEventForm = ({ rowData, state, type, getTableItem }) => {
  const useStated = useStateData();
  const { closePopup } = usePopup();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();

  const showFormAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("event.event_regis_success") : t("event.event_regis_fail"),
      message: isSuccess ? t("event.new_event_regis_success") : t("event.new_event_regis_fail"),
      iconURL: isSuccess ? false : true,
    });
  };

  const now = new Date();
  const yyyyMMdd = now.getFullYear() + "-" + String(now.getMonth() + 1).padStart(2, "0") + "-" + String(now.getDate()).padStart(2, "0");

  const useForm = useFormData({
    // 1. 이벤트 기준 정보
    title: "",
    type: '',
    img: "",
    img_data: {
      img_data: null,
      img_exec: null,
      img_name: null,
      img_proc: null,
      img_size: null,
      img_uuid: "",
    },
    start_datetime: yyyyMMdd,
    end_datetime: yyyyMMdd,
    content: "",

    popup: "N",
    main_notice: "N",
    event_notice: "N",

    // 2. 이벤트 조건 설정
    event_type: "DF",

    min_money: 0,
    max_money: 0,
    max_payment: 0,

    rolling_casion: 0,
    rolling_slot: 0,
    rolling_sports: 0,
    rolling_minigame: 0,

    active: "",

    // 3. 이벤트 상세 조건 등록

    // 일 별 제약조건
    condition_day_YN: "N",
    condition_day: ["N", "N", "N", "N", "N", "N", "N", "N"],
    // 시간 별 제약조건
    condition_time_YN: "N",
    condition_start_time: "",
    condition_end_time: "",
    condition_endless: "N",

    // 누적 입금 제약조건
    condition_deposit_YN: "N",
    condition_min_deposit: 0,
    condition_max_deposit: 0,
    condition_deposit_nolimit: "N",
    // 누적 출금 제약조건
    condition_withdrawal_YN: "N",
    condition_min_withdrawal: 0,
    condition_max_withdrawal: 0,
    condition_withdrawal_nolimit: "N",

    bonus_payment_type: "C",

    bonus_arr: [
      { condition_value: 0, payment_value: 0, },
    ],

    // 4. 관리자 메모
    admin_memo: "",
  });

  useEffect(() => {
    // 여기서 데이터 매핑
    if (rowData) {
      useForm.setFormValue(prev => ({ ...prev, ...rowData }));
    }
  }, [rowData]);

  console.log(useForm.formValue);

  useEffect(() => {
    // 기존 데이터가 있으면 유지
    // R비율지급 이니 bonus_arr 초기화
    if (useForm.formValue.bonus_payment_type === "R") {
      if (useForm?.formValue?.bonus_arr.length <= 1) return;
      useForm.setFormValue(prev => ({
        ...prev, bonus_arr: [
          {
            condition_value: 0,
            payment_value: 0,
          }
        ]
      }));
    }
  }, [useForm.formValue.bonus_payment_type]);

  /**
   * 이벤트 등록 및 수정
   */
  const insertEvent = () => {
    const { popup, main_notice, event_notice } = useForm.formValue;
    let isError = '';
    if (!([useForm.formValue.popup, useForm.formValue.main_notice, useForm.formValue.event_notice].includes("Y"))) {
      isError += t("event.event_insert_confirm") + "\n";
    }

    useForm.formValue.bonus_arr.forEach((item) => {
      if (item.payment_value == 0) {
        isError += t("event.check_bonus_payment_type") + "\n";
      }
    })

    if (isError) {
      openAlert({
        title: t("event.event_insert"),
        message: isError, //t("event.check_bonus_payment_type"),
        iconURL: false,
      });
      return;
    }

    const apiUrl = state == "insert" ? "/v1/event-management/insertEvent" : "/v1/event-management/updateEvent";
    api
      .post(apiUrl, {
        ...useForm.formValue,
        dispay_region: popup + main_notice + event_notice,
        type: type,
      })
      .then(() => {
        showFormAlert(true);
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showFormAlert(false);
      });
  };

  const showInserEventConfirm = () => {
    // is New?
    openConfirm({
      title: t("event.new_event_regis"),
      message: t("event.new_event_regis_confirm"),
      iconURL: false,
      mainBtn: t("common.check"),
      Func: () => insertEvent(),
    });
  };
  console.log(useForm.formValue);
  return (
    <>
      <Row className="area-box" $gap="20px" $width="1600px">
        {/* 왼쪽 */}
        <Column $width="50%" $gap="10px" $jus="space-between">
          {/* 1. 이벤트 기준 정보 */}
          <>
            <TextBox $line="var(--l-sub)" $weight="bold">
              {`1. ${t("event.event_info")}`}
            </TextBox>
            {/* 이벤트 명 */}
            <Row $gap="10px">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("event.name")}
              </TextBox>
              <InputForm maxWidth="100%" type="text" name="title" {...useForm} {...useStated} />
            </Row>
            {/* 베너 이미지 등록 */}

            {/* <DisabledWrapper disabled={!state}> */}
            <Row $gap="10px">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {/* 이미지 */}
                {t("event.img")}
              </TextBox>
              <ImageForm name="img" groupNm="img_data" width="60px" height={"60px"} {...useForm} {...useStated} />
            </Row>
            {/* </DisabledWrapper> */}

            {/* 이벤트 기간 */}
            <Row $gap="10px">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {/* 시작일 */}
                {t("event.startDate")}
              </TextBox>
              <CalendarFilter startName="start_datetime" bottom {...useForm} {...useStated} start />
            </Row>

            <Row $gap="10px">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {/* 종료일 */}
                {t("event.endDate")}
              </TextBox>
              <CalendarFilter startName="end_datetime" bottom {...useForm} {...useStated} start />
            </Row>

            {/* 이벤트 문구 */}
            <Row $gap="10px">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("event.content")}
              </TextBox>

              <TextareaEditorForm
                name="content"
                width="100%"
                height="140px"
                minWidth="100%"
                maxWidth="100%"
                {...useForm}
                {...useStated}
              ></TextareaEditorForm>
            </Row>

            {/* 이벤트 등록 */}
            <Row $gap="10px" $pad="18px 0 0 0">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("event.event_insert")}
              </TextBox>

              <Row $width="100%" $gap="10px">
                <Row>
                  <EachCheckForm
                    labelFor="event.popup"
                    name="popup"
                    options={checkboxYNEnum}
                    labelName={t("event.popup")}
                    {...useForm}
                    {...useStated} />
                </Row>

                <Row>
                  <EachCheckForm
                    labelFor="event.event_notice"
                    name="event_notice"
                    options={checkboxYNEnum}
                    labelName={t("event.event_notice")}
                    {...useForm}
                    {...useStated} />
                </Row>

                <Row>
                  <EachCheckForm
                    labelFor="event.main_notice"
                    name="main_notice"
                    options={checkboxYNEnum}
                    labelName={t("event.main_notice")}
                    {...useForm}
                    {...useStated} />
                </Row>
              </Row>
            </Row>
          </>

          <>
            {/*2. 이벤트 조건 설정*/}
            <TextBox $line="var(--l-sub)" $weight="bold">
              {`2. ${t("event.event_condition_type")}`}
            </TextBox>

            {/* 타입선택 */}
            {
              useForm.formValue.type === 'D' &&
              <Row $gap="10px">
                <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                  {t("event.select_type")}
                </TextBox>
                <RadioForm name="event_type"
                  options={dbSiteEventMng.deposit_type}
                  {...useForm}
                  {...useStated} />
              </Row>
            }

            {/* 최소 금액 */}
            <Row $gap="10px">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("event.min_amount")}
              </TextBox>
              <InputForm
                type="text"
                name="min_money"
                placeholder={t("common.input")}
                validationType={validationTypeEnum.NUMBER}
                tAlign="right"
                {...useForm}
                {...useStated}
              />
            </Row>

            {/* 최대 금액 */}
            <Row $gap="10px">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("event.max_amount")}
              </TextBox>
              <InputForm
                type="text"
                name="max_money"
                placeholder={t("common.input")}
                validationType={validationTypeEnum.NUMBER}
                tAlign="right"
                {...useForm}
                {...useStated}
              />
            </Row>

            {/* 최대 지급 금액 */}
            <Row $gap="10px">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("event.max_payment")}
              </TextBox>
              <InputForm
                type="text"
                name="max_payment"
                placeholder={t("common.input")}
                validationType={validationTypeEnum.NUMBER}
                tAlign="right"
                {...useForm}
                {...useStated}
              />
            </Row>

            {/* 롤링 조건 */}
            <Row $gap="10px">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("event.rolling_condition")}
              </TextBox>

              <Column $gap="10px">
                <Row $gap="10px" $jus="space-between">
                  {/* 카지노 */}
                  <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                    {t("event.casino")}
                  </TextBox>
                  <InputForm
                    type="text"
                    name="rolling_casion"
                    placeholder={t("common.input")}
                    validationType={validationTypeEnum.NUMBER}
                    tAlign="right"
                    {...useForm}
                    {...useStated}
                  />

                  {/* 슬롯 */}
                  <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                    {t("event.slot")}
                  </TextBox>
                  <InputForm
                    type="text"
                    name="rolling_slot"
                    placeholder={t("common.input")}
                    validationType={validationTypeEnum.NUMBER}
                    tAlign="right"
                    {...useForm}
                    {...useStated}
                  />
                </Row>

                <Row $gap="10px" $jus="space-between">
                  {/* 스포츠 */}
                  <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                    {t("event.sports")}
                  </TextBox>
                  <InputForm
                    type="text"
                    name="rolling_sports"
                    placeholder={t("common.input")}
                    validationType={validationTypeEnum.NUMBER}
                    tAlign="right"
                    {...useForm}
                    {...useStated}
                  />

                  {/* 미니게임 */}
                  <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                    {t("event.mini_game")}
                  </TextBox>
                  <InputForm
                    type="text"
                    name="rolling_minigame"
                    placeholder={t("common.input")}
                    validationType={validationTypeEnum.NUMBER}
                    tAlign="right"
                    {...useForm}
                    {...useStated}
                  />
                </Row>
              </Column>
            </Row>
          </>

        </Column>

        <LineSection $borL="1px solid var(--c-gray-300)" />

        {/* 오른쪽 */}
        <Column $width="50%" $jus="space-between">
          <>
            {/* 3. 이벤트 상세 조건 등록 */}
            <TextBox $line="var(--l-sub)" $weight="bold">
              {`3. ${t("event.event_detail_condition")}`}
            </TextBox>

            {/* 제약 조건 */}
            <Row $gap="10px">
              <TextBox $width="150px" $minWidth="150px" $line="var(--l-sub)">
                {t("event.condition_type")}
              </TextBox>

              <Row $width="100%" $gap="10px" >
                <Column>
                  <EachCheckForm
                    labelFor="event.day"
                    name="condition_day_YN"
                    options={checkboxYNEnum}
                    labelName={t("event.day")}
                    {...useForm}
                    {...useStated} />
                </Column>

                <Column>

                  <EachCheckForm
                    labelFor="event.time"
                    name="condition_time_YN"
                    options={checkboxYNEnum}
                    labelName={t("event.time")}
                    {...useForm}
                    {...useStated} />
                </Column>

                <Column>

                  <EachCheckForm
                    labelFor="event.accumulate_deposit"
                    name="condition_deposit_YN"
                    options={checkboxYNEnum}
                    labelName={t("event.accumulate_deposit")}
                    {...useForm}
                    {...useStated} />
                </Column>

                <Column>
                  <EachCheckForm
                    labelFor="event.accumulate_withdrawal"
                    name="condition_withdrawal_YN"
                    options={checkboxYNEnum}
                    labelName={t("event.accumulate_withdrawal")}
                    {...useForm}
                    {...useStated} />
                </Column>
              </Row>
            </Row>



            {/* 일 별 제약조건 */}
            {useForm.formValue.condition_day_YN === "Y" && (
              <Row $gap="10px">
                <TextBox $width="150px" $minWidth="150px" $line="var(--l-sub)">
                  {t("event.day_condition")}
                </TextBox>
                <Row $gap="12px" $align="center">
                  <Row>
                    <EachCheckFormCustom
                      labelFor="plan-all-days"
                      name="condition_day"
                      index={0}
                      options={checkboxYNEnum}
                      {...useForm}
                      {...useStated}
                    />
                    <Label htmlFor="plan-all-days">{t("days.every_day")}</Label>
                  </Row>
                  <Row>
                    <EachCheckFormCustom
                      labelFor="plan-sun"
                      name="condition_day"
                      index={1}
                      options={checkboxYNEnum}
                      {...useForm}
                      {...useStated}
                    />
                    <Label htmlFor="plan-sun">{t("days.sunday")}</Label>
                  </Row>
                  <Row>
                    <EachCheckFormCustom
                      labelFor="plan-mon"
                      name="condition_day"
                      index={2}
                      options={checkboxYNEnum}
                      {...useForm}
                      {...useStated}
                    />
                    <Label htmlFor="plan-mon">{t("days.monday")}</Label>
                  </Row>
                  <Row>
                    <EachCheckFormCustom
                      labelFor="plan-tue"
                      name="condition_day"
                      index={3}
                      options={checkboxYNEnum}
                      {...useForm}
                      {...useStated}
                    />
                    <Label htmlFor="plan-tue">{t("days.tuesday")}</Label>
                  </Row>
                  <Row>
                    <EachCheckFormCustom
                      labelFor="plan-wen"
                      name="condition_day"
                      index={4}
                      options={checkboxYNEnum}
                      {...useForm}
                      {...useStated}
                    />
                    <Label htmlFor="plan-wen">{t("days.wednesday")}</Label>
                  </Row>
                  <Row>
                    <EachCheckFormCustom
                      labelFor="plan-thur"
                      name="condition_day"
                      index={5}
                      options={checkboxYNEnum}
                      {...useForm}
                      {...useStated}
                    />
                    <Label htmlFor="plan-thur">{t("days.thursday")}</Label>
                  </Row>
                  <Row>
                    <EachCheckFormCustom
                      labelFor="plan-fri"
                      name="condition_day"
                      index={6}
                      options={checkboxYNEnum}
                      {...useForm}
                      {...useStated}
                    />
                    <Label htmlFor="plan-fri">{t("days.friday")}</Label>
                  </Row>
                  <Row>
                    <EachCheckFormCustom
                      labelFor="plan-sat"
                      name="condition_day"
                      index={7}
                      options={checkboxYNEnum}
                      {...useForm}
                      {...useStated}
                    />
                    <Label htmlFor="plan-sat">{t("days.saturday")}</Label>
                  </Row>
                </Row>
              </Row>
            )}


            {/* 시간 별 제약조건 */}
            {useForm.formValue.condition_time_YN === "Y" && (
              <Row $gap="10px">
                <TextBox $width="150px" $minWidth="150px" $line="var(--l-sub)">
                  {t("event.time_condition")}
                </TextBox>

                <Row $gap="4px" $align="center">
                  <InputForm
                    width="120px"
                    type="text"
                    name="condition_start_time"
                    placeholder="23:59:59"
                    maskType="time"
                    {...useForm}
                    {...useStated}
                  />
                  <TextBox>~</TextBox>
                  <InputForm
                    width="120px"
                    type="text"
                    name="condition_end_time"
                    placeholder="23:59:59"
                    maskType="time"
                    {...useForm}
                    {...useStated}
                  />

                  <EachCheckForm
                    labelFor="event.no_end_time"
                    name="condition_endless"
                    options={checkboxYNEnum}
                    labelName={t("event.no_end_time")}
                    {...useForm}
                    {...useStated} />
                </Row>
              </Row>
            )}

            {/* 누적 입금 제약조건 */}
            {useForm.formValue.condition_deposit_YN === "Y" && (
              <Row $gap="10px">
                <TextBox $width="150px" $minWidth="150px" $line="var(--l-sub)">
                  {t("event.accumulate_deposit_condition")}
                </TextBox>

                <Row $gap="4px">
                  <InputForm
                    type="text"
                    name="condition_min_deposit"
                    placeholder={t("common.input")}
                    validationType={validationTypeEnum.NUMBER}
                    tAlign="right"
                    {...useForm}
                    {...useStated}
                  />
                  <TextBox>~</TextBox>
                  <InputForm
                    type="text"
                    name="condition_max_deposit"
                    placeholder={t("common.input")}
                    validationType={validationTypeEnum.NUMBER}
                    tAlign="right"
                    {...useForm}
                    {...useStated}
                  />

                  <EachCheckForm
                    labelFor="event.no_limit"
                    name="condition_deposit_nolimit"
                    options={checkboxYNEnum}
                    labelName={t("event.no_limit")}
                    {...useForm}
                    {...useStated} />
                </Row>
              </Row>
            )}

            {/* 누적 출금 제약조건 */}
            {useForm.formValue.condition_withdrawal_YN === "Y" && (
              <Row $gap="10px">
                <TextBox $width="150px" $minWidth="150px" $line="var(--l-sub)">
                  {t("event.accumulate_withdrawal_condition")}
                </TextBox>
                <Row $gap="4px">
                  <InputForm
                    type="text"
                    name="condition_min_withdrawal"
                    placeholder={t("common.input")}
                    validationType={validationTypeEnum.NUMBER}
                    tAlign="right"
                    {...useForm}
                    {...useStated}
                  />
                  <TextBox>~</TextBox>
                  <InputForm
                    type="text"
                    name="condition_max_withdrawal"
                    placeholder={t("common.input")}
                    validationType={validationTypeEnum.NUMBER}
                    tAlign="right"
                    {...useForm}
                    {...useStated}
                  />
                  <EachCheckForm
                    labelFor="event.no_limit"
                    name="condition_withdrawal_nolimit"
                    options={checkboxYNEnum}
                    labelName={t("event.no_limit")}
                    {...useForm}
                    {...useStated} />
                </Row>
              </Row>
            )}

            {/* 보너스 지급 방식 */}
            <Row $gap="10px">
              <TextBox $width="150px" $minWidth="150px" $line="var(--l-sub)">
                {t("event.bonus_payment_type")}
              </TextBox>
              <RadioForm name="bonus_payment_type"
                options={dbSiteEventMng.bonus_payment_type}
                {...useForm}
                {...useStated}
              />
            </Row>

            {/* 보너스 지급 방식 추가 */}
            <Row $gap="10px">
              <Column>
                <TextBox $width="150px" $minWidth="150px" $line="var(--l-sub)">
                  {t("event.add_bonus_payment_type")}
                </TextBox>
              </Column>

              <Column $gap="10px">
                {
                  useForm.formValue.bonus_arr.length > 0 ? (

                    useForm.formValue.bonus_arr.map((item, index) => (
                      <Row $gap="10px" $align="center">
                        {
                          useForm.formValue.bonus_payment_type === "C" && (
                            <>
                              <InputForm
                                type="text"
                                name="condition_value"
                                groupNm="bonus_arr"
                                index={index}
                                placeholder={t("common.input")}
                                tAlign="right"
                                validationType={validationTypeEnum.NUMBER}
                                {...useForm}
                                {...useStated}
                              />
                              <TextBox>
                                {t("event.deposit_amount")}
                              </TextBox>
                            </>
                          )
                        }

                        <InputForm
                          type="text"
                          name="payment_value"
                          groupNm="bonus_arr"
                          index={index}
                          placeholder={t("common.input")}
                          tAlign="right"
                          validationType={validationTypeEnum.DECIMAL}
                          {...useForm}
                          {...useStated}
                        />
                        {/* 원 지급 or % 지급 */}
                        <TextBox>
                          {useForm.formValue.bonus_payment_type === "C" ? t("event.bonus_amount") : t("event.bonus_percent")}
                        </TextBox>

                        {/* 추가하기 */}
                        {
                          useForm.formValue.bonus_payment_type === "C" && (
                            <MainBtn
                              $width="60px"
                              $height="34px"
                              index={index}
                              onClick={() => { useForm.setFormValue(prev => ({ ...prev, bonus_arr: [...prev.bonus_arr, { deposit_amount: 0, bonus_amount: 0 }] })) }}>
                              {t("event.add_bonus")}
                            </MainBtn>
                          )
                        }

                        {/* 삭제하기 */}
                        {((useForm.formValue.bonus_payment_type === "C" && index !== 0)) && (
                          <RedBtn
                            $width="60px"
                            $height="34px"
                            index={index}
                            onClick={() => { useForm.setFormValue(prev => ({ ...prev, bonus_arr: prev.bonus_arr.filter((_, i) => i !== index) })) }}>
                            {t("event.delete_bonus")}
                          </RedBtn>
                        )}
                      </Row>
                    ))
                  )
                    :
                    useForm.formValue.bonus_payment_type === "C" && (
                      <MainBtn
                        $width="60px"
                        $height="34px"
                        index={0}
                        $backColor="var(--c-blue)"
                        onClick={() => { useForm.setFormValue(prev => ({ ...prev, bonus_arr: [...prev.bonus_arr, { deposit_amount: 0, bonus_amount: 0 }] })) }}>
                        {t("event.add_bonus")}
                      </MainBtn>
                    )

                }

              </Column>
            </Row>


          </>


          <br />

          {/*4. 관리자 메모 */}
          <>
            <TextBox $line="var(--l-sub)" $weight="bold">
              {`4. ${t("event.admin_memo")}`}
            </TextBox>
            <TextareaForm
              width="100%"
              height="100px"
              name="admin_memo"
              {...useForm}
              {...useStated}
            />
          </>


          {/* 버튼 */}
          <Row $jus="space-between" $pad="8px 0">

            {/* 전체 초기화 */}
            <MainBtn $width="150px" $height="34px" onClick={() => { useForm.resetForm() }}>
              {t("event.clear")}
            </MainBtn>

            <Row $gap="8px" $align="center">
              {/* 활성화 여부 체크박스 */}
              <EachCheckForm
                labelFor="event.no_end_time"
                name="active"
                options={checkboxYNEnum}
                labelName={t("event.active_yn")}
                {...useForm}
                {...useStated} />
              {/* 활성화 등록 */}
              <MainBtn $width="86px" $height="34px" onClick={() => { showInserEventConfirm() }}>
                {t("common.save")}
              </MainBtn>
              {/* 비활성화 등록 */}
              <RedBtn $width="86px" $height="34px" onClick={() => { closePopup() }}>
                {t("common.cancel")}
              </RedBtn>
            </Row>
          </Row>
        </Column>
      </Row>
    </>
  );
};

export default InsertEventForm;
