import { Row } from "style/custom/useCustomLayout";
import styled from "styled-components";

const ImgPopup = ({ src }) => {
  const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

  return (
    <Row className="area-box" $gap="20px" $width="1200px" $height="600px"
      $jus="center" $align="center">
      <StyledImage
        src={src}
        alt="img"
      />
    </Row>
  );
};

export default ImgPopup;
